/*******************************************************************************
 * Admin.js
 * 
 * Admin javascript application definition - a thin wrapper around the other
 * components that are used for the 4-H Photo Uploader Admin Portal.
 ******************************************************************************/
import React from "react";
import { Box, Snackbar, Alert } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import BestBetterAdminNavigator from './AdminNavigation';
import CssBaseline from '@mui/material/CssBaseline';
import { getDesignTokens, getSavedThemeMode, setSavedThemeMode } from "../theme";
import "./Admin.css";
import GeneralSettingsView from "./views/GeneralSettings";
import DistrictNamesView from "./views/DistrictNames";
import NationalEventsView from "./views/NationalEvents";
import StateEventsView from "./views/StateEvents";
import DistrictEventsView from "./views/DistrictEvents";
import AdminAccountView from "./views/AdminAccount";
import EmailSettingsView from "./views/EmailSettings";
import ServerLogsView from './views/ServerLogs';
import PhotoStorageView from "./views/PhotoStorage";
import CountyEventsView from "./views/CountyEvents";

const pageView=()=>{
  var view = new URLSearchParams(window.location.search).get("view");
  if (!view) {
    view = "";
  }
  return view;
}

export default function Admin() {
  const [pageLoadComplete, setPageLoadComplete] = React.useState(false);
  const [mode, setMode] = React.useState(getSavedThemeMode());
  const [theme, setTheme] = React.useState(createTheme(getDesignTokens(mode)));
  const [activePage, setActivePage] = React.useState(pageView());
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [severity, setSeverity] = React.useState("success");

  const prefersDarkMode = useMediaQuery(
    '(prefers-color-scheme: dark)',
    { noSsr: true }
  );
  
  React.useEffect(()=>{

    // Theme Default Setter
    const setDefaultTheme = (themePreference) => {
      // Set the default color profile - only if we haven't done so before!
      if (!pageLoadComplete) {
        var defaultTheme = getSavedThemeMode();
        if (defaultTheme === null){
          defaultTheme = (themePreference ? 'dark' : 'light');
          setSavedThemeMode(defaultTheme);
        }
        setMode(defaultTheme);
        document.documentElement.setAttribute('data-color-mode', defaultTheme);
        setTheme(createTheme(getDesignTokens(defaultTheme)));
        setPageLoadComplete(true);
      }
    }

    // Load Requisites when page Completes
    setDefaultTheme(prefersDarkMode);
  },[prefersDarkMode, pageLoadComplete]);

  // Theme Changer Function
  const toggleThemeSetting = () => {
    var newTheme = (mode === 'light' ? 'dark' : 'light');
    setSavedThemeMode(newTheme);
    setMode(newTheme);
    setTheme(createTheme(getDesignTokens(newTheme)));
  }

  const handlePopup = (data) => {
    // Show Popup with Defined Message and Severity
    console.log(data.message);

    // Update Snackbar
    setAlertMessage(data.message);
    setSeverity(data.severity);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <BestBetterAdminNavigator
          mode={mode}
          pageTitle={activePage}
          onThemeChange={toggleThemeSetting}
          onPageChange={() => {setActivePage(pageView())}}
          albumsRedirect={window.application_info.albums_redirect}
        >
          {activePage === "" && <GeneralSettingsView onPopup={handlePopup} />}
          {activePage === "General" && <GeneralSettingsView />}
          {activePage === "District Names" && <DistrictNamesView />}
          {activePage === "National Events" && <NationalEventsView />}
          {activePage === "State Events" && <StateEventsView />}
          {activePage === "District Events" && <DistrictEventsView />}
          {activePage === "County Events" && <CountyEventsView />}
          {activePage === "Photo Storage" && <PhotoStorageView />}
          {activePage === "Email Settings" && <EmailSettingsView />}
          {activePage === "Admin Account" && <AdminAccountView />}
          {activePage === "Server Logs" && <ServerLogsView />}
        </BestBetterAdminNavigator>
        <Snackbar
          open={!!alertMessage}
          autoHideDuration={6000}
          onClose={() => {setAlertMessage(null)}}
        >
          <Alert
            onClose={() => {setAlertMessage(null)}}
            severity={severity}
            sx={{ width: '100%' }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
}
