/*******************************************************************************
 * PhotoUploadForm - The General Upload Form for the Photo Tool.
 ******************************************************************************/

import React from 'react';
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  FormLabel,
  FormControl,
  FormControlLabel,
  Snackbar,
  Radio,
  RadioGroup,
  Link,
  Typography,
  LinearProgress,
  Breadcrumbs
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MuiAlert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { DropzoneArea } from 'react-mui-dropzone';
import uploadEventPhotos from './api/upload';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export default function PhotoUploadForm(props) {
  const [files, setFiles] = React.useState([]);
  const [district, setDistrict] = React.useState("");
  const [competitionType, setCompetitionType] = React.useState(null);
  const [alert, setAlert] = React.useState(null);
  const [severity, setSeverity] = React.useState("success");
  const [dropzoneKey, setDropzoneKey] = React.useState(0);
  const [progress, setProgress] = React.useState(null);
  const [uploadPercentStepSize, setUploadPercentStepSize] = React.useState(null);

  const pathname = window.location.pathname.split("/");

 const handleBreadcrumbClick = (event) => {
    event.preventDefault();
    window.location.href = event.target.href;
  }

  const handleSwitchChange = () => {
    if (!!competitionType) {
      setCompetitionType(null);
    } else {
      setCompetitionType('district-competition');
    }
  };

  const handlePopup = (data) => {
    // Show Popup with Defined Message and Severity
    console.log(data.message);

    // Update Snackbar
    setAlert(data.message);
    setSeverity(data.severity);
    setProgress(null);
  };

  const handleSuccessfulUpload = (filename) => {
    if (!!filename) {
      // Remove the Image That was Just Uploaded
      let newFiles = files.filter(
        function(f){return f.name !== filename;}
      )
      setFiles(newFiles);
      setDropzoneKey(dropzoneKey + 1);
      setProgress(progress + uploadPercentStepSize);
    } else {
      // Turn Off Spinner, Reset Files List
      setFiles([]);
      setProgress(null);
      setDropzoneKey(0);
    }

    // Update Snackbar
    if (files.length === 0) {
      setAlert("Successfully uploaded images.");
      setSeverity("success");

      // Turn Off Spinner, Reset Files List
      setFiles([]);
      setProgress(null);
      setDropzoneKey(dropzoneKey+1);
    }
  };

  // Photo Uploader Action
  const uploadPhotos = (event) => {
    event.preventDefault();
    // Validate Photo List
    if (files.length === 0) {
      setAlert("Select one or more photos, first!");
      setSeverity("warning");
      return // Don't attempt the upload
    }
    // Validate Competition Configuration
    if (!!competitionType && district === '') {
      setAlert("Select a district, first!");
      setSeverity("warning");
      return // Don't attempt the upload
    }
    // Indicate Photos Are Being Submitted
    setProgress(1); // start with non-zero value to inform the user we're working
    setUploadPercentStepSize(100 / files.length);

    // Determine Region Selection
    var pageUrl = new URL(window.location.href);

    // Perform Upload
    uploadEventPhotos({
      files: files,
      regionEventSelection: pageUrl.pathname,
      uploadOptions: {
        district_name: district,
        competition_type: competitionType,
      },
      onPopup: handlePopup,
      onSuccess: handleSuccessfulUpload
    });
  }

  return (
    <Box sx={{
      minWidth: 200,
      maxWidth: 1400,
    }}>
      <div>
        <div role="presentation" onClick={handleBreadcrumbClick}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              {props.title}
            </Link>
            {pathname.slice(1, -1).map((path, idx) => (
              <Link underline="hover" color="inherit" href={
                pathname.slice(0, pathname.indexOf(path) + 1).join("/")
              }>
                {idx === 0 &&
                  toTitleCase(decodeURI(path))
                }
                {idx > 0 &&
                  decodeURI(path)
                }
              </Link>
            ))}
            <Typography color="text.primary">
              {decodeURI(pathname.slice(-1))}
            </Typography>
          </Breadcrumbs>
        </div>
        {(window.application_info.district_competition_option.toLowerCase() === "true") &&
          <>
          <FormControlLabel fullWidth
          control={<Switch
            checked={!!competitionType}
            onChange={handleSwitchChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />}
          label="Upload as Part of District Competition"
          />
          {!!competitionType && <Box>
          <FormControl fullWidth>
            <InputLabel id="district-select-label">District</InputLabel>
            <Select
              labelId="district-select"
              id="district-select"
              value={district}
              label="District"
              onChange={(e) => {setDistrict(e.target.value)}}
            >
              {props.districts.map((district, _) => (
                <MenuItem value={district}>{district}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <br/>
          <br/>
          <FormLabel id="event-participation-type-label">Competition Type</FormLabel>
          <RadioGroup
            row
            aria-labelledby="event-participation-type"
            name="event-participation-type"
            value={competitionType}
            onChange={(e) => {setCompetitionType(e.target.value)}}
          >
            <FormControlLabel value="district-competition" control={<Radio />} label="Best Photo Contest" />
            <FormControlLabel value="scavenger-hunt" control={<Radio />} label="Scavenger Hunt" />
          </RadioGroup>
          </Box>}
          </>
        }
        <FormLabel component="legend">&nbsp;</FormLabel>
        <FormControl fullWidth my={3}>
          <DropzoneArea
            key={dropzoneKey}
            clearOnUnmount="true"
            acceptedFiles={['image/*', 'video/*']}
            dropzoneText={"Drag or click to select images"}
            onChange={setFiles}
            filesLimit={50}
            maxFileSize={100_000_000}
            fileObjects={files}
            showPreviewsInDropzone={true}
            useChipsForPreview={true}
            showAlerts={['error', 'info']}
          />
        </FormControl>
        <FormControl id="PhotoUploadSubmit" fullWidth mt={3}>
          <LoadingButton
            onClick={uploadPhotos}
            endIcon={<SendIcon />}
            loading={!!progress}
            loadingPosition="end"
            variant="contained"
          >
            Submit Photos
          </LoadingButton>
        </FormControl>
      </div>
      {!!progress &&
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      }
      <Snackbar
        open={!!alert}
        autoHideDuration={6000}
        onClose={() => {setAlert(null)}}
      >
        <Alert
          onClose={() => {setAlert(null)}}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {alert}
        </Alert>
      </Snackbar>
    </Box>
  );
}

