/*******************************************************************************
 * UploadInterface.js
 * 
 * Main javascript application definition - a thin wrapper around the other
 * components that are used for the 4-H Photo Uploader.
 ******************************************************************************/
import React from "react";
import { Grid } from "@mui/material";
import ConsentDialogForm from './components/ConsentDialog';
import PhotoUploadForm from "./PhotoUploadForm";
import "./App.css";


export default function UploadApp() {
  const [districtsList, setDistrictsList] = React.useState([]);

  // Run any code that needs to be executed on page load.
  React.useEffect(()=>{

    // Populate District List
    fetch("/api/v1/districts/list", {
      method: "GET",
      headers: {'Accept': 'application/json'},
    }).then(res => res.json()).then(jsonData => {
      // Set the Page Information
      setDistrictsList(jsonData);
    }).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });

  },[]);

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        style={{ minHeight: '90vh' }}
      >
        <Grid item m={2} pt={3} xs={3}>
          <PhotoUploadForm
            title={document.title}
            districts={districtsList}
          />
        </Grid>
      </Grid>
      <ConsentDialogForm />
    </>
  );
}
