/* Controlled and Customized Fields. */
import * as React from 'react';
import {
    Button, Box, Dialog, DialogActions, DialogContent, Divider, DialogTitle,
    IconButton, Typography, Tooltip, LinearProgress, List, ListItem,
    TextField,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { withStyles } from '@mui/styles';
import axios from "axios";
import { fetchToken } from '../../auth';
import { api_client } from '../../api';

export class UploadFilesService {
    upload(file, targetPath, onUploadProgress) {
      let formData = new FormData();
  
      formData.append("file", file);
  
      return axios({
        method: 'post',
        url: `/api/v1/${targetPath}`,
        data: formData,
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${fetchToken()}`
        },
        onUploadProgress: onUploadProgress,
      });
    }
  }

export function EventImageList({
    onSelect, // callback fired when an event image is selected
  }) {
    const [images, setImages] = React.useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState(undefined);
    const [customImageId, setCustomImageId] = React.useState(null);
  
    React.useEffect(()=>{
      // Load Requisites when page Completes
      getImagesList();
    },[]);
  
    const getImagesList = () => {
      api_client.get("banner-images/available", {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        },
      }).then(res => res.data).then(jsonData => {
        // Record the Grant Cycles
        setImages(jsonData);
      }).catch((error) => {
        if( error.response ){
          console.log(error.response.data); // => the response payload
        }
      });
    }
  
    const removeImage = (removedImagePath) => {
      setDeleteDialogOpen(false);
      api_client.delete("banner-images/",
      {
        params: {
          image: removedImagePath
        },
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        },
      }).then(res => res.data).then(jsonData => {
        // Reload Images
        getImagesList();
      }).catch((error) => {
        if( error.response ){
          console.log(error.response.data); // => the response payload
        }
      });
    }
  
    const promptImageRemoval = (removedImagePath) => {
      setSelectedImage(removedImagePath);
      api_client.get("banner-images/images-in-use", {
        params: {
          image: removedImagePath
        },
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        },
      }).then(res => res.data).then(jsonData => {
        // Open Dialog if Image is in Use
        if (jsonData.length > 0) {
          setDeleteDialogOpen(true);
        } else {
          // Just Delete the Unused Image
          removeImage(removedImagePath);
        }
      }).catch((error) => {
        if( error.response ){
          console.log(error.response.data); // => the response payload
        }
      });
    }

    return (
      <>
        <br/>
        <Divider />
        <Dialog
          open={deleteDialogOpen}
          onClose={() => {setDeleteDialogOpen(false)}}
          fullWidth
          maxWidth="md"
          aria-labelledby="delete-image-dialog-title"
          aria-describedby="delete-image-dialog-description"
        >
          <DialogTitle>Confirm Image Deletion</DialogTitle>
          <DialogContent>
            Deleting a image will render it inaccessible for any notification
            letters which may already have been sent.
            <p>Are you certain you wish to delete this image?</p>
            <img
              // TODO: Update with a variable for the URL
              src={`${window.application_info.albums_redirect}/api/Photo::getArchive?photoIDs=${selectedImage}&kind=THUMB`}
              alt="selection-to-delete" height="100"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {removeImage(selectedImage)}}>
              Delete Image
            </Button>
            <Button
              onClick={() => {setDeleteDialogOpen(false)}}
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <br/>
        <Typography variant="h6" className="list-header">
          Available Images
        </Typography>
        <List>
          <ListItem key="custom-image-id">
            <Box sx={{ flexGrow: 1 }}>
              {!!customImageId &&
              <img
                // TODO: Update with a variable for the URL
                src={`${window.application_info.albums_redirect}/api/Photo::getArchive?photoIDs=${customImageId}&kind=THUMB`}
                alt="" height="80"
              />
              }
            </Box>
            <TextField
              id="custom-image-id"
              variant="filled"
              size="small"
              label="Specific Image ID"
              onChange={(e) => {setCustomImageId(e.target.value)}}
            />
            &nbsp;
            <Button variant='contained' onClick={() => {onSelect(customImageId)}}>
              Use this Image
            </Button>
          </ListItem>
          {images.map((imageId) => (
            <ListItem
              key={imageId}
            >
              <Box sx={{ flexGrow: 1 }}>
              <img
                // TODO: Update with a variable for the URL
                src={`${window.application_info.albums_redirect}/api/Photo::getArchive?photoIDs=${imageId}&kind=THUMB`}
                alt="" height="80"
              />
              </Box>
              <Button variant='contained' onClick={() => {onSelect(imageId)}}>
                Use this Image
              </Button>
              &nbsp;
              <Tooltip title="Permanently Remove this Image">
                <IconButton
                  onClick={() => {promptImageRemoval(imageId)}}
                >
                  <DeleteIcon color='error'/>
                </IconButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </>
    )
  }


const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#006600',
  },
}))(LinearProgress);

export function SelectEventImageForm ({
  open, // status of dialog (open or closed)
  onClose = null, // callback issued when dialog is closed
  onUploadComplete = null, // callback fired when upload finishes
  title = "Upload", // dialog title
  uploadPath = null, // server upload path
  children, // component children
}) {
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  const [selectedFiles, setSelectedFiles] = React.useState(undefined);
  const [currentFile, setCurrentFile] = React.useState(undefined);
  const UploadService = new UploadFilesService();

  const upload = () => {
    let currentFile = selectedFiles[0];

    setProgress(0);
    setCurrentFile(currentFile);

    UploadService.upload(currentFile, uploadPath, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {
        if (!!response.data) {
          if (Object.hasOwn(response.data, "message")) {
            setMessage(response.data.message);
          } else {
            setMessage(response.data);
          }
        } else {
          setMessage("");
        }
        
        setIsError(false);
      })
      .catch((e) => {
        console.log(e);
        setProgress(0);
        setCurrentFile(undefined);
        setIsError(true);
        setMessage("Could not upload the file!");
      });

    setProgress(0);
    setCurrentFile(undefined);
    setSelectedFiles(undefined);
    if (!!onUploadComplete) {
      onUploadComplete(message);
    }
    
  }

  const doCloseAction = () => {
    setProgress(0);
    setCurrentFile(undefined);
    onClose();
  }
    
  return (
    <Dialog
      open={open}
      onClose={doCloseAction}
      fullWidth
      maxWidth="md"
      aria-labelledby="upload-dialog-title"
      aria-describedby="upload-dialog-description"
    >
      <DialogTitle id="upload-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <div className="mg20">
          {currentFile && (
            <Box className="mb25" display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <BorderLinearProgress variant="determinate" value={progress} />
              </Box>
              <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
              </Box>
            </Box>)
          }

          <label htmlFor="btn-upload">
            <input
              id="btn-upload"
              name="btn-upload"
              style={{ display: 'none' }}
              type="file"
              onChange={(e) => {setSelectedFiles(e.target.files)}} />
            <Button
              className="btn-choose"
              variant="contained"
              component="span" >
              Choose File
            </Button>
          </label>
          <div className="file-name">
          {selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}
          </div>

          <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
            {message}
          </Typography>
          {children}
        </div >
      </DialogContent>
      <DialogActions>
        <Button
          className="btn-upload"
          color="primary"
          variant="contained"
          component="span"
          disabled={!selectedFiles}
          onClick={upload}>
          Upload
        </Button>
        <Button onClick={doCloseAction} variant="contained">Close</Button>
      </DialogActions>
    </Dialog>
  );
}

