/*******************************************************************************
 * EventTable - A table for managing event configuration in the 4-H photo
 * upload tool.
 ******************************************************************************/

import * as React from 'react';
import { Button, Container, Collapse, Table, TableBody, TableContainer,
  TableHead, TableRow, Typography, IconButton, Paper, Stack } from '@mui/material';
import {
  EditableTextField,
  EditableBooleanField
} from './EditableField';
import DeleteIcon from '@mui/icons-material/Delete';
import { SelectEventImageForm, EventImageList } from './UploadDialog';
import { StyledTableRow, StyledTableCell } from './SettingRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';

function EventRow({
    eventObject, // JSON object describing the event
    onChange, // callback to modify the event data
    onStore, // callback to push modified changes to server
    onRemove, // callback to delete the indicated item from the server
    noDistrictComp = false, // control to disable district competition switch
}) {
  const [uploadFormOpen, setUploadFormOpen] = React.useState(false);
  const [editActive, setEditActive] = React.useState(false);
  const [open, setOpen] = React.useState(false);

    const doChange = (key, value) => {
        let data = eventObject;
        data[key] = value;
        onChange(data);
    }
  
    const toggleEdit = () => {
      setEditActive(!editActive);
      if (editActive) {
        onStore(eventObject.event_id);
      }
    }

    const afterUploadComplete = () => {
      setUploadFormOpen(false);
    }

    const selectEventImage = (imageId) => {
      doChange("image_id", imageId);
      setUploadFormOpen(false);
    }


    return(
      <>
        <SelectEventImageForm
          open={uploadFormOpen}
          onClose={() => {setUploadFormOpen(false)}}
          onUploadComplete={afterUploadComplete}
          title="Select Event Image"
        >
          <EventImageList onSelect={selectEventImage}/>
        </SelectEventImageForm>
        <StyledTableRow
          key={eventObject.event_id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <StyledTableCell component="tr" scope="row">
            <div style={{
              display: 'inline-flex',
              VerticalAlign: 'text-bottom',
              BoxSizing: 'inherit',
              textAlign: 'center',
              AlignItems: 'center'
            }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              &nbsp;
              <EditableTextField
                value={eventObject.name}
                inEditMode={editActive}
                setValue={(value) => {doChange("name", value)}}
              />
          </div>
        </StyledTableCell>
        <StyledTableCell component="tr" scope="row">
            <EditableTextField
            value={eventObject.slug}
            inEditMode={editActive}
            setValue={(value) => {doChange("slug", value)}}
            />
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow
        key={eventObject.event_id + "_data"}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stack direction="row" spacing={2} sx={{ margin: 1 }}>
              <Stack direction="column" spacing={1}>
                <div style={{
                  display: 'inline-flex',
                  VerticalAlign: 'text-bottom',
                  BoxSizing: 'inherit',
                  textAlign: 'center',
                  AlignItems: 'center'
                }}>
                  {!noDistrictComp && <>
                  <Typography variant="subtitle1">
                    District Competition
                  </Typography>
                  <EditableBooleanField
                  value={eventObject.uses_district_competition}
                  inEditMode={editActive}
                  setValue={(value) => {doChange("uses_district_competition", value)}}
                  /></>}
                </div>
                <div style={{
                  display: 'inline-flex',
                  VerticalAlign: 'text-bottom',
                  BoxSizing: 'inherit',
                  textAlign: 'center',
                  AlignItems: 'center'
                }}>
                  <Typography variant="subtitle1">
                    Scavenger Hunt
                  </Typography>
                  <EditableBooleanField
                  value={eventObject.uses_scavenger_hunt}
                  inEditMode={editActive}
                  setValue={(value) => {doChange("uses_scavenger_hunt", value)}}
                  />
                </div>
              </Stack>
              <IconButton
                variant="outlined"
                color="secondary"
                disabled={!editActive}
                onClick={() => {setUploadFormOpen(true)}}
              >
                <img
                  // TODO: Update with a variable for the URL
                  src={`https://albums.idaho4h.org/api/Photo::getArchive?photoIDs=${eventObject.image_id}&kind=THUMB`}
                  alt="" width="80"
                />
              </IconButton>
              <IconButton
                variant="outlined"
                onClick={toggleEdit}
              >
                {editActive ? <SaveIcon /> : <EditIcon />}
              </IconButton>
              <IconButton
                variant="outlined"
                color="error"
                onClick={() => {onRemove(eventObject.event_id)}}
              >
                <DeleteIcon/>
              </IconButton>
            </Stack>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}

export function BestBetterEventTable({
    data, // list of objects corresponding to rows
    onAdd, // callback when add button used
    onRemove, // callback when delete button used
    onChange, // callback to change values
    onStore, // callback to push modified changes to server
    noDistrictComp = false, // control to disable district competition switch
    disableAdd = false, // control to disable adding events
  }) {
  
     return (
      <Container maxWidth={false}>
        <TableContainer component={Paper}>
          <Table
            aria-label="simple table"
            sx={{
              '& > :not(style)': { m: 1, width: '100hw', minWidth: '800px' },
            }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Event Name</StyledTableCell>
                <StyledTableCell>Slug</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((entry) => (
                <EventRow
                  eventObject={entry}
                  onChange={onChange}
                  onStore={onStore}
                  onRemove={onRemove}
                  noDistrictComp={noDistrictComp}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Button variant="contained" onClick={onAdd} disabled={disableAdd}>
          Add
        </Button>
       </Container>
     );
  }