/*******************************************************************************
* Admin Navigation
*******************************************************************************/
import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
// Icons
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import MailIcon from '@mui/icons-material/Mail';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import LaunchIcon from '@mui/icons-material/Launch';
import ListIcon from '@mui/icons-material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import StorageIcon from '@mui/icons-material/Storage';
import UploadIcon from '@mui/icons-material/Upload';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
// End Icons
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import { clearToken } from './auth';

const drawerWidth = 240;

function USAIcon() {
  return (
    <SvgIcon color="text.primary" sx={{ fill: "text.primary" }}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
        width="25px" height="25px" viewBox="0 0 260 165" enable-background="new 0 0 260 165" xmlSpace="preserve">
      <path d="M258,23.765l-5.217-3.73l-2.621-7.979l-6.633-0.567l-1.653,11.521l-2.101,3.329l-1.298,2.219l-7.767,2.124l-6.445,1.535
        l-5.194,6.728l1.464,3.116l-10.883,6.894l-3.329,4.225l-3.116,2.314l-8.428,5.005l-4.651-1.417l3.589-8.475l-5.312-7.389
        l-1.558-6.185l-3.966-2.125l-6.799,6.209l-1.393,5.312l2.054,6.704l-2.03,7.625l-3.093,0.496l-1.723-4.533l-0.874-4.697l1.676-9.656
        l-1.345-1.723l2.101-5.194l12.701-1.818l-4.58-4.532l-9.963,2.762l-4.886-3.353l-8.169,3.919l-7.294-0.732l2.502-7.979l-5.17-2.88
        l-4.58,0.26l-5.902-2.857l-8.38-0.023l-27.928-1.417l-34.491-4.698l-14.07-2.88l-3.942-0.897L24.687,2.235l-1.605,5.052
        l-7.744-2.644l-0.189,12.512l2.715,1.228l-4.084,2.738L5.919,36.136l-0.142,5.406L2,50.347l0.236,9.868l7.649,30.784l8.853,4.958
        l4.107,5.477l0.803,5.265l12.441,1.628l-0.779,1.181l18.367,10.788l10.246,1.606l4.721-2.149l8.924,1.181l7.176,7.554l1.96,6.445
        l7.106,4.863l2.88-4.485l6.893,0.236l9.703,14.188l2.101,7.342l9.962,2.03l-1.274-5.571l3.021-6.374l7.838-4.462l9.655-7.72
        l8.31,2.526l2.243-1.935l5.43,4.06l6.043-1.983l0.874-5.028l5.902-1.677l5.193-1.038l7.342,0.33l6.351,3.589l4.674-3.683
        l8.522,6.515l-0.094,6.729l5.288,7.932l10.08,5.807l0.567-11.048l-4.061-8.782l-7.483-12.819l1.865-8.546l7.531-8.522l2.266-4.816
        l11.638-14.637l-2.455-3.966l0.071-0.024l0.236-0.047l0.425-0.094l0.118-0.024l-3.99-3.588l0.307-4.084l-1.354-1.354l4.116,0.339
        l1.204-0.591l0.756-2.927l0.142-0.023l0.047-0.024l-3.636-7.649l1.558-1.676l4.604,0.685l-0.945-7.72l1.181-0.354l7.554-3.943
        l2.078-3.092l0.236,0.141l0.59,0.874l2.101-2.526l-3.022-5.926l-0.047-1.298l3.99-5.973L258,23.765z M35.9,132.856l-2.856,1.015
        l-6.964,3.305l-3.565,5.335l0.921,7.153l-2.999,5.572l7.98,7.53l20.373-2.549l-0.755-22.239L35.9,132.856z"/>
      </svg>
    </SvgIcon>
  )
}

function IdahoIcon() {
  return (
    <SvgIcon color="text.primary" sx={{ fill: "text.primary" }}>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 474.57295 736.48784">
      <path d="M532.648,784.47834c-40.0886,0-338.28387,4.171-464.39325.98574,0-1.39595,2.51721-128.12414,
        5.7565-191.01083.09788-1.89953.37353-3.8083,2.56232-5.17591,1.10336-.68932,1.30852-3.267,1.39844-5.01757a12.17847,
        12.17847,0,0,1,2.01354-6.37679c1.75267-2.76511,1.36825-6.28085-1.08887-9.05678-.30706-1.18827-.46854-1.92212,
        1.18331-2.66823,1.075-.48564,2.41582-1.0213,2.98806-2.13515.65885-1.28242-1.08035-3.8511,
        1.11633-4.34537.24567-.05528.5556-.09028.64118-.327a.5218.5218,0,0,0-.00683-.30459,2.89059,2.89059,0,0,
        0-1.74316-1.71415,9.69444,9.69444,0,0,1-2.25372-1.12783,5.26937,5.26937,0,0,1-1.53095-3.44608,4.10826,4.10826,0,0,
        0-.349-1.40749,2.46262,2.46262,0,0,0-3.47766-.5351c-.51519.358-1.07042.8964-1.66405.69348a1.35926,1.35926,0,0,
        1-.61619-.53509c-.63467-.87886-.99608-1.93188-1.636-2.807a2.75371,2.75371,0,0,
        0-2.76807-1.288c-1.21787.3436-2.07877,1.93627-3.3042,1.62068a2.40426,2.40426,0,0,
        1-.91919-.57282c-1.73718-1.49162-4.72313-4.34714-2.14862-6.4364,
        1.35474-1.0993.73469-1.49612.09255-2.52053a24.2874,24.2874,0,0,1-2.06216-4.72451,2.04492,2.04492,0,0,1,
        .21691-1.66283,6.13068,6.13068,0,0,0,1.08407-5.95579,4.20365,4.20365,0,0,1,1.00878-3.72857c5.63942-3.97266,
        6.48242-10.43944,8.82094-16.12913.42221-1.02721.82786-2.06331,1.189-3.11324,1.59928-4.64929,4.34008-8.06235,
        9.32661-9.48925,1.07869-.3087,1.88339-1.78788,2.6777-2.83187a63.75818,63.75818,0,0,0,
        4.40118-6.17622c1.96062-3.339,3.67657-6.71669,2.16592-10.91343-.85375-2.37172-.8488-4.768,1.82251-6.664,
        1.44462-1.02527,2.01713-3.23522,3.09231-4.83737,1.11483-1.66118,2.51735-3.14494,3.50269-4.87245a19.5026,
        19.5026,0,0,0,1.80143-4.733c.85805-3.29416.86737-6.8403,3.06348-9.72186a2.733,2.733,0,0,0,
        .4463-1.30529c.81218-7.15949,4.86123-13.55291,9.14892-19.16024,1.14073-1.49182.64343-3.28561,
        1.41686-4.97228.66532-1.45089,1.73664-2.67,2.53688-4.04256.59086-1.0134,1.41742-1.88658,
        2.09794-2.851,2.45769-3.48275-.1617-5.9867-1.59427-8.80644a15.40368,15.40368,0,0,1-1.74034-5.43578,
        7.97032,7.97032,0,0,0-6.82546-7.18888,4.50249,4.50249,0,0,
        1-2.78411-1.664c-1.25524-1.70175-2.53292-2.90027-4.83064-2.43941a2.26434,2.26434,0,0,
        1-2.79328-1.68827,16.18937,16.18937,0,0,
        0-1.90328-2.73746c-.5647-.87739-.47263-1.76974-.86727-2.68936-.25459-.59325-.97648-.8843-1.48051-1.37252a9.75618,
        9.75618,0,0,1-2.26872-3.91457,58.74047,58.74047,0,0,
        0-2.80109-6.62308c-.782-1.616-1.09343-3.53743-1.74128-5.23871a9.6682,9.6682,0,0,
        0-1.42268-2.009l.083.09013a3.02385,3.02385,0,0,1-.81729-3.4c.32511-.898.88414-1.69668,1.21123-2.59393a17.31838,
        17.31838,0,0,1,1.598-3.69332c.4706-.74087,
        1.25291-1.75918.86919-2.68715-.39293-.95026-1.60755-.87681-1.95778-1.91427a40.08717,40.08717,0,0,
        1-.8903-4.63522l.0322.04338c-.77571-3.37182-1.25821-5.3564-3.41835-8.00846-1.90129-2.33426-2.09647-4.67443-1.88908-7.66546.85591-12.3437,
        4.70182-105.67,5.67317-143.24674.28345-10.96512,5.08161-131.40245,5.71674-131.39121h68.73878c0,1.10891-1.086,
        72.17219-2.37556,107.77884a7.66269,7.66269,0,0,0,1.26614,4.11163,91.92342,91.92342,0,0,0,6.17185,8.65837c1.91278,
        2.35483,4.72,3.53973,5.96562,6.459.954,2.23591,1.01277,4.8545,2.39746,6.85251a20.2781,20.2781,0,0,0,3.35985,3.19,
        5.53733,5.53733,0,0,1,2.16325,3.95038c-.0109.654-.21383,1.29789-.18087,1.95113.10576,2.09646,2.2819,3.22617,
        2.85473,5.12548.60647,2.01087-.87409,3.29078-2.33518,4.46191-1.57721,1.2642-1.78977,1.53657-.6745,2.99585,
        1.06708,1.39622,3.91632,5.258,3.61469,7.19634-.27012,1.73584-2.15327,2.05668-2.80736,3.578-.40689.94637.24928,
        1.67088,1.00023,2.22921a82.85985,82.85985,0,0,0,7.23089,4.82373c2.46165,1.45616,4.14512,3.28162,4.578,
        6.24528a3.12362,3.12362,0,0,0,1.82911,1.96227c3.659,1.29059,7.38334,2.39854,11.09649,3.532,1.43451.43792,3.7437,
        4.00787,3.55131,5.73988-.14471,1.30364-.32233,2.481,1.5891,2.49069a1.39856,1.39856,0,0,1,1.04048.81316c.92237,
        3.69207,4.52315,4.79457,6.79663,7.16872,1.94683,2.03293,2.57542,4.23446,4.141,6.49143.97172,1.40083,2.55717,
        2.42756,3.03786,4.06325.24975.84988.163,1.76713.38033,2.62588s.9291,1.72533,1.80908,1.62375c6.05765-.69927,
        8.2061,8.61021,7.59492,13.19589-.628,4.71159,6.24768,3.225,8.05049,5.84832a12.21458,12.21458,0,0,0,1.29212,
        2.02936c.45527.44131,1.08346.70189,1.44982,1.21939,1.15177,1.627-1.127,4.1225.0059,
        5.76265l5.14944-2.78537a3.24719,3.24719,0,0,1,2.74847-.53765,3.2088,3.2088,0,0,1,1.26126,1.31949c.76105,1.18979,
        1.55521,2.56758,1.19176,3.93239-.1178.44238-.3535.85-.43741,1.3a2.73187,2.73187,0,0,0,1.52976,2.71127,8.00721,
        8.00721,0,0,0,3.22018.73168l6.19319.524c1.59358.13482,2.3826-1.0949,3.643-1.86351a7.59753,7.59753,0,0,1,
        4.31608-1.2378c2.62492.10782,4.09436,1.7834,3.5423,4.3157-.81144,3.722-1.3922,7.49378-2.172,
        11.22335-.16528.79043-.6278,1.98751-1.1884,2.14147-1.92319.52827-2.16664,1.35828-1.20253,2.97879,1.78307,
        2.9973.91269,5.58149-1.47165,7.80592-.97852.91283-.999,1.79763-.89721,3.15189a45.21787,45.21787,0,0,1-.48409,
        8.24291c-.06977.63561-.71715,1.6664-1.18956,1.7204-2.14756.24531-1.80222,1.73481-1.63392,3.016.25248,
        1.92219.72761,3.814,1.07281,5.72518.4448,2.46251-.17869,3.09647-2.65912,3.41062a3.64634,3.64634,0,0,0-2.52008,
        1.44175c-.60378,1.15965.33523,1.94348,1.47179,2.5256,2.34512,1.20117,2.99321,5.55445,1.21817,7.53609-2.11673,
        2.36311-1.76594,4.072,1.165,5.6762,3.54149,1.93825,3.51682,1.93366,2.74883,6.10237a2.964,2.964,0,0,0,.47636,
        1.5397c.59854,1.271,1.31869,2.48846,1.86,3.78185a2.70313,2.70313,0,0,1-2.748,3.83856c-4.40089-.30569-8.9148,
        3.28563-9.103,7.71212-.05528,1.30243,1.24585,2.78562,2.18306,3.98449,1.02575,2.0515,2.36759,3.5855,1.89533,
        6.0679-.41821,2.19832-1.99092,3.37672-3.63517,4.68753a1.01753,1.01753,0,0,0-.31248.339c-.39853.85179,1.27777,
        1.88056.84579,2.94546-1.88547,4.648,2.6145,4.11565,4.49947,5.68534l-.0712-.0844c2.42909,2.0035,4.86513,3.99876,
        7.28152,6.01746.48474.40493.87791.91743,1.34337,1.348.65081.6022,1.54629,1.7906,1.95739,1.64625a13.16085,13.16085,
        0,0,0,3.8585-2.2881c.48388-.39612.40873-1.41242.73816-2.07163.96783-1.937,1.00906-4.03927,4.76258-3.55711,
        4.47124.57444,7.17138-3.43091,9.38578-7.11825.81818-1.36251,2.05785-2.46737,2.92773-3.80522a5.97827,5.97827,0,0,0,
        .95766-2.71928c.20178-2.92932,1.68942-4.01318,4.54746-3.12236a7.11873,7.11873,0,0,1,5.1559,7.16242,1.45437,
        1.45437,0,0,0,.79954,1.02506,24.583,24.583,0,0,0,4.47217,1.562c1.61184.31766,1.61169,1.30571,1.44318,
        2.46938-.10541.7279-.371,1.43142-.5429,2.15108-.16086.67352-1.19884,3.30745-.86122,3.879.325.55012,3.02146.52258,
        3.69266.6576,1.97207.39673,1.61183,4.50413.84972,5.78489-.286.48064-.68673.8886-.942,1.386a3.88663,3.88663,0,0,
        0-.22822,2.54944,6.49417,6.49417,0,0,0,1.86912,3.365,3.68955,3.68955,0,0,1,1.3065,1.64681,3.47646,3.47646,0,0,
        1-.043,1.20248c-.52931,4.15717,2.56629,7.14073,4.38235,10.52262,1.693,3.15271,4.19266,5.74568,6.23462,8.65939,
        1.64492,2.34718,2.22574,5.92982,4.25834,7.78458a44.82744,44.82744,0,0,0,5.07978,4.32565,3.90173,3.90173,0,0,1,
        2.05576,3.9033,29.254,29.254,0,0,0,.02022,5.62874c.24538,1.91229.045,3.23515-2.1287,
        3.67106-.33674.06755-.86408.896-.7624,1.1765,1.17851,3.25143,1.21559,6.99233,3.97087,
        9.57659.39568.37116.93047.91563,1.35821.87913,2.89447-.24675,3.94032,1.70606,5.04547,3.81648a2.14829,2.14829,0,0,
        0,2.02566.4889c1.30479-.60256,2.41632-1.608,3.67271-2.33565,1.04248-.60371,2.00164-1.0422,3.23177.10892a12.00605,
        12.00605,0,0,0,4.12031,2.31292c4.06118,1.45756,4.135,5.89392,6.1218,9.17248,1.27423,2.10266,3.08153,4.13933,
        3.0216,6.59723-.05865,2.4056-1.94253,4.773-1.07207,7.01639.70653,1.82089,2.9172,2.59353,3.95253,4.2497.92143,
        1.474.75061,3.36164.45075,5.07388a4.25262,4.25262,0,0,0-.00085,2.269,3.45276,3.45276,0,0,0,.92164,1.21542c1.91957,
        1.79146,4.33743,2.87827,6.51731,4.29781a13.845,13.845,0,0,1,3.0095,2.47648c.66042.76713,1.25317,2.17369,2.50493,
        2.05722,1.14285-.10633,1.46129-1.43907,1.06525-2.32865-.73849-1.6572-1.83489-3.5834-.35112-5.40233,
        2.22784-2.73115,4.00287-6.26385,7.85785-6.57223,3.41267-.273,6.85046.68878,10.14438,1.44756,1.4871.34257,
        3.02953.77291,4.53361.97528,1.66029.22339,3.32-.226,4.97671-.00591,1.72123.22867,3.31812,2.08555,5.10653,1.5422,
        1.95248-.59321,1.86058-3.30556,2.18862-5.31966a7.753,7.753,0,0,1,6.52334-6.268,4.77271,4.77271,0,0,1,
        3.2415.62605c.65136.43871,1.13529,1.0876,1.76793,1.55291a7.30077,7.30077,0,0,0,4.22817,1.03941,47.89078,47.89078,
        0,0,0,14.70829-1.98455c4.14972-1.23682,6.94276-2.7833,9.27182,2.01589.75674,1.5593,1.49895,1.91528,2.91216.66558,
        1.03947-.91907,2.408-1.46405,3.45694-2.37488,2.858-2.48157,6.01417-1.716,9.22135-1.12458a5.37572,5.37572,0,0,0,
        2.36778-.30548c1.043-.30819,2.21842-.89481,3.33073-.61134.77284.197,2.45808,2.02947,2.91406,
        1.0097.21958-.49108-.48373-1.85645-.719-2.30573a6.122,6.122,0,0,1-.99574-3.61466c.27429-2.32591,2.146-4.103,
        3.14122-6.223a2.63173,2.63173,0,0,0,.
        29727-1.81377c-.09776-.33481-.307-.62757-.41446-.9594-.37456-1.15669.57892-2.32685,1.60887-2.97295,3.36831-2.113,
        6.0681-5.67083,9.67144-1.86591,1.59191,1.681,3.00287,3.51808,4.439,5.33055,1.31491,1.65952,3.01373,3.178,4.07347,
        5.01155a5.41375,5.41375,0,0,1,.696,4.12169,2.08841,2.08841,0,0,0-.16084,1.1623,1.48953,1.48953,0,0,0,
        .43749.60839c1.58975,1.51166,3.74978,2.25978,5.52492,3.54871a3.10409,3.10409,0,0,1,.9709,1.00262c.60014,
        1.1339.36468,2.26047,1.41022,3.19617a7.2252,7.2252,0,0,0,3.24907,1.3931c1.16194.27227,1.64239.65859,1.65831,
        1.9937.009.7811.04224,1.56374.07823,2.3438,3.89418,84.40067,4.317,168.94193,9.19834,253.30877C535.00686,
        783.59175,534.65563,784.42068,532.648,784.47834Z" transform="translate(-60.35131 -50.17486)"/>
      </svg>
    </SvgIcon>
  )
}

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export default function BestBetterAdminNavigator({
  mode, // light/dark mode
  pageTitle, // the page title
  onThemeChange, // callback when theme changes
  onPageChange, // callback when the page changes
  albumsRedirect, // redirect link for albums/gallery view
  children,
}) {
  const [drawerOpen, setDrawerOpen] = React.useState(true);
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setDrawerOpen(false)
    } else {
      setDrawerOpen(true)
    }
  }

  // create an event listener
  React.useEffect(() => {
    window.addEventListener("resize", handleResize)
  });

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handlePageChange = (pageName) => {
    // Push the State to change the URL (Without Reloading Page)
    window.history.pushState(
      pageName,
      decodeURI(pageName),
      `/admin?view=${pageName}`
    );
    onPageChange(); 
  }


  const onLogout = () => {
    // Clear active token (to log out)
    clearToken();
    // and return to app-interface
    window.location.href = "/";
  }

  const topNavigation = [
    {'description': 'General', 'icon': <SettingsApplicationsIcon />,},
    {'description': 'District Names', 'icon': <EditLocationAltIcon />,},
    {'description': 'National Events', 'icon': <USAIcon />},
    {'description': 'State Events', 'icon': <IdahoIcon />},
    {'description': 'District Events', 'icon': <TodayIcon />},
    {'description': 'County Events', 'icon': <EventIcon />}
  ];
  const middleNavigation = [
    {'description': 'Admin Account', 'icon': <AdminPanelSettingsIcon />},
    {'description': 'Email Settings', 'icon': <MailIcon />},
    {'description': 'Photo Storage', 'icon': <SettingsSuggestIcon />},
    {'description': 'Server Logs', 'icon': <StorageIcon />}
  ];
  const bottomNavigation = [
    {
      'description': 'Upload Interface',
      'icon': <UploadIcon />,
      'href': "/",
    },
    {
      'description': 'Gallery',
      'icon': <LaunchIcon />,
      'href': albumsRedirect,
    },
  ];

  return (
    <>
      <AppBar
        position="fixed"
        open={drawerOpen}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
          >
            { drawerOpen ? <MenuOpenIcon /> : <ListIcon /> }
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {pageTitle.length > 0 ? pageTitle : 'Admin Settings'}
          </Typography>
          <div>
            <Tooltip title="Log Out">
              <IconButton sx={{ ml: 1 }} onClick={onLogout} color="inherit">
                <LogoutIcon />
              </IconButton>
              </Tooltip>
            <IconButton sx={{ ml: 1 }} onClick={onThemeChange} color="inherit">
              {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={drawerOpen}
        onClose={handleDrawerToggle}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {topNavigation.map((navInfo, _) => (
              <ListItem key={navInfo.description} disablePadding>
                <ListItemButton onClick={()=>{handlePageChange(navInfo.description);}}>
                  <ListItemIcon>
                    {navInfo.icon}
                  </ListItemIcon>
                  <ListItemText primary={navInfo.description} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {middleNavigation.map((navInfo, _) => (
              <ListItem key={navInfo.description} disablePadding>
                <ListItemButton onClick={()=>{handlePageChange(navInfo.description);}}>
                  <ListItemIcon>
                    {navInfo.icon}
                  </ListItemIcon>
                  <ListItemText primary={navInfo.description} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {bottomNavigation.map((navInfo, _) => (
              <ListItem key={navInfo.description} disablePadding>
                <ListItemButton href={navInfo.href}>
                  <ListItemIcon>
                    {navInfo.icon}
                  </ListItemIcon>
                  <ListItemText primary={navInfo.description} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {children}
      </Box>
    </>
  );
}
