import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { QRCodeCanvas } from 'qrcode.react';


export default function PageQrCode({open, onClickClose}) {
    const ref = React.useRef();
    const location = window.location.href;
    const [computedWidth, setComputedWidth] = React.useState();
  
    React.useEffect(()=>{
      calculateQRWidth(window.innerWidth)
      window.addEventListener(
        'resize',
        ()=>calculateQRWidth(window.innerWidth)
      );
    },[]);
  
    const calculateQRWidth = (innerWidth) => {
      let maxWidth = innerWidth * 11/16;
      setComputedWidth(Math.min(maxWidth, 500));
    }
  
    const downloadQRCode = () => {
      const canvas = document.querySelector('.qr-code-canvas > canvas');
      var link = document.createElement('a');
      var name = decodeURI(window.location.pathname.replace('/', '-'));
      link.download = `qr${name}.png`;
      link.href = canvas.toDataURL();
      link.click();
      link.remove();
    }
  
    return (
      <Dialog
        open={open}
        onClose={onClickClose}
        aria-labelledby="qr-code-link-dialog-title"
        aria-describedby="qr-code-link-dialog-description"
      >
        <DialogTitle id="qr-code-link-dialog-title">
          {"QR Code Sharable Link"}
        </DialogTitle>
        <DialogContent>
          <div id="qr-code-canvas" className="qr-code-canvas">
            <QRCodeCanvas
              itemRef={ref}
              value={location}
              size={computedWidth}
              level='H'
              includeMargin={true}
              imageSettings={
                (computedWidth < 72 * 3)
                ? null
                : {
                  src:"/static/react/clover-72x72.png",
                  height: `${Math.min(computedWidth/10, 72)}`,
                  width: `${Math.min(computedWidth/10, 72)}`,
                  excavate:true,
                }
              }
            />
          </div>
          <Stack direction="row" spacing={1} >
            <IconButton
              aria-label="copy"
              onClick={() => {navigator.clipboard.writeText(location)}}
            >
              <ContentCopyIcon/>
            </IconButton>
            <Paper
              elevation={3}
              variant='outlined'
              sx={{ p:1 }}
              style={{maxWidth: '100%', overflow: 'auto'}}
            >
              <Typography variant='body1' align='center'>
                {location}
              </Typography>
            </Paper>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={downloadQRCode} color="secondary">
            Download
          </Button>
          <Button onClick={onClickClose} color="secondary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }